import React from 'react';

const AuthorsList = React.lazy(() => import('pages/author/authorsList/authorsList'));
const AuthorAdd = React.lazy(() => import('pages/author/authorAdd/index'));

const AuthorEdit = React.lazy(() => import('pages/author/authorEdit/index'));

export const authorPagesConfigs = [
  {
    path: '/author/list',
    element: <AuthorsList />,
  },
  {
    path: '/author/add',
    element: <AuthorAdd />,
  },

  {
    path: '/author/edit/:id',
    element: <AuthorEdit />,
  },
];
