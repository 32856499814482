import React from 'react';

const AppContentsList = React.lazy(
  () => import('pages/appContents/appContentsList/appContentsList'),
);
const AppContentsAdd = React.lazy(() => import('pages/appContents/appContentsAdd/appContentsAdd'));

const AppContentsEdit = React.lazy(
  () => import('pages/appContents/appContentsEdit/appContentsEdit'),
);

const SplashList = React.lazy(() => import('pages/appContents/splash/index'));
const SplashAdd = React.lazy(() => import('../../components/appContents/splashes/add/index'));

const SplashEdit = React.lazy(() => import('../../components/appContents/splashes/edit/index'));

export const appContentsPagesConfigs = [
  {
    path: '/app-contents/list',
    element: <AppContentsList />,
  },
  {
    path: '/app-contents/add',
    element: <AppContentsAdd />,
  },
  {
    path: '/app-contents/edit/:id',
    element: <AppContentsEdit />,
  },

  {
    path: '/splash',
    element: <SplashList />,
  },

  {
    path: '/splash/Add',
    element: <SplashAdd />,
  },

  {
    path: '/splash/edit/:id',
    element: <SplashEdit />,
  },
];
